'use strict'

angular
.module 'mundoAdmin.dispatch'
# .config ($stateProvider) ->
#   $stateProvider
#     .state 'dispatch',
#       url: '/dispatch'
#       parent: 'admin'
#       templateUrl: 'mundo-admin/dispatch/views/dispatch.tpl.html'
#       controller: 'TabsCtrl'
#       controllerAs: 'dispatchManagementCtrl'
#       deepStateRedirect: {default: {state: 'dispatch.groups'}},

#     .state 'dispatch.groups',
#       url: '/groups'
#       data:
#         'selectedTab': 0
#       views:
#         'groups@dispatch':
#           templateUrl: 'mundo-components/mundo-data-table/views/mundo-data-table.tpl.html'
#           controller: 'DataTableCtrl'
#           controllerAs: 'listCtrl'
#           resolve:
#             entityManager: (DispatchGroupsManager) ->
#               DispatchGroupsManager
#             pageTitle: ->
#               'app.admin.pageTitles.dispatching.dispatchGroups'
#             pageDescription: ->
#               'app.admin.descriptions.dispatching.dispatchGroups'
#             deleteBoxTitle: ->
#               'app.admin.actions.dispatching.deleteDispatchGroup'
#             deleteBoxBody: ->
#               'app.admin.actions.dispatching.deleteDispatchGroup'
#             AddBoxTitle: ->
#               'app.admin.actions.dispatching.addDispatchGroup'
#             editBoxTitle: ->
#               'app.admin.actions.dispatching.editDispatchGroup'
#             loadFilterParams: ->
#               {}
#     .state 'dispatch.unitTypes',
#       url: '/types'
#       data:
#         'selectedTab': 1
#       views:
#         'unitTypes@dispatch':
#           templateUrl: 'mundo-components/mundo-data-table/views/mundo-data-table.tpl.html'
#           controller: 'DataTableCtrl'
#           controllerAs: 'listCtrl'
#           resolve:
#             entityManager: (DispatchUnitTypesManager) ->
#               DispatchUnitTypesManager
#             pageTitle: ->
#               'app.admin.pageTitles.dispatching.unitTypes'
#             pageDescription: ->
#               'app.admin.descriptions.dispatching.unitTypes'
#             deleteBoxTitle: ->
#               'app.admin.actions.dispatching.deleteUnitType'
#             deleteBoxBody: ->
#               'app.admin.actions.dispatching.deleteUnitType'
#             AddBoxTitle: ->
#               'app.admin.actions.dispatching.addUnitType'
#             editBoxTitle: ->
#               'app.admin.actions.dispatching.editUnitType'
#             loadFilterParams: ->
#               {}
#     .state 'dispatch.unitStatuses',
#       url: '/statuses'
#       data:
#         'selectedTab': 2
#       views:
#         'unitStatuses@dispatch':
#           templateUrl: 'mundo-components/mundo-data-table/views/mundo-data-table.tpl.html'
#           controller: 'DataTableCtrl'
#           controllerAs: 'listCtrl'
#           resolve:
#             entityManager: (DispatchUnitStatusesManager) ->
#               DispatchUnitStatusesManager
#             pageTitle: ->
#               'app.admin.pageTitles.dispatching.unitStatuses'
#             pageDescription: ->
#               'app.admin.descriptions.dispatching.unitStatuses'
#             deleteBoxTitle: ->
#               'app.admin.actions.dispatching.deleteUnitStatus'
#             deleteBoxBody: ->
#               'app.admin.actions.dispatching.deleteUnitStatus'
#             AddBoxTitle: ->
#               'app.admin.actions.dispatching.addUnitStatus'
#             editBoxTitle: ->
#               'app.admin.actions.dispatching.editUnitStatus'
#             loadFilterParams: ->
#               {}
#     .state 'dispatch.unitContactTypes',
#       url: '/contactTypes'
#       data:
#         'selectedTab': 3
#       views:
#         'unitContactTypes@dispatch':
#           templateUrl: 'mundo-components/mundo-data-table/views/mundo-data-table.tpl.html'
#           controller: 'DataTableCtrl'
#           controllerAs: 'listCtrl'
#           resolve:
#             entityManager: (DispatchUnitContactTypesManager) ->
#               DispatchUnitContactTypesManager
#             pageTitle: ->
#               'app.admin.pageTitles.dispatching.unitContactTypes'
#             pageDescription: ->
#               'app.admin.descriptions.dispatching.unitContactTypes'
#             deleteBoxTitle: ->
#               'app.admin.actions.dispatching.deleteUnitContactType'
#             deleteBoxBody: ->
#               'app.admin.actions.dispatching.deleteUnitContactType'
#             AddBoxTitle: ->
#               'app.admin.actions.dispatching.addUnitContactType'
#             editBoxTitle: ->
#               'app.admin.actions.dispatching.editUnitContactType'
#             loadFilterParams: ->
#               {}
#     .state 'dispatch.units',
#       url: '/units'
#       data:
#         'selectedTab': 4
#       views:
#         'units@dispatch':
#           templateUrl: 'mundo-components/mundo-data-table/views/mundo-data-table.tpl.html'
#           controller: 'DataTableCtrl'
#           controllerAs: 'listCtrl'
#           resolve:
#             entityManager: (DispatchUnitsManager) ->
#               DispatchUnitsManager
#             pageTitle: ->
#               'app.admin.pageTitles.dispatching.units'
#             pageDescription: ->
#               'app.admin.descriptions.dispatching.units'
#             deleteBoxTitle: ->
#               'app.admin.actions.dispatching.deleteUnit'
#             deleteBoxBody: ->
#               'app.admin.actions.dispatching.deleteUnit'
#             AddBoxTitle: ->
#               'app.admin.actions.dispatching.addUnit'
#             editBoxTitle: ->
#               'app.admin.actions.dispatching.editUnit'
#             loadFilterParams: ->
#               {}
#     .state 'dispatch.intercad',
#       url: '/intercad'
#       data:
#         'selectedTab': 5
#       views:
#         'intercad@dispatch':
#           templateUrl: 'mundo-admin/dispatch/views/dispatch-intercad.tpl.html'
#           controller: 'DispatchIntercadCtrl'
#           controllerAs: 'dispatchIntercadCtrl'
